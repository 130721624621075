@font-face {
  font-family: 'Margarosa';
  src: local('Margarosa'), url(../../../public/fonts/Margarosa.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

.formContainer {
  margin-bottom: 200px !important;
    .form {
      .input-group {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
        font-size: 20px;
      }

      .input-group label {
        width: 150px;
        margin-right: 10px;
        font-size: 20px;
        flex: 0 0 auto;
        text-align: left;
      }


      .input-group textarea {
        width: 300px;
        height: 207px;
        box-sizing: border-box;
        border-radius: 5px;
        border-color: #70a132;
        font-size: 20px;
      }

      .input-group input {
        width: 300px;
        height: 40px;
        box-sizing: border-box;
        border-radius: 5px;
        border-width: 1px;
        border-color: #70a132;
        font-size: 20px;
      }

      button {
        background-color: #ffc30c;
        border: none;
        border-radius: 3px;
        font-size: 16px;
        height: 44px;
        width: 300px;
        margin-left: 160px;
        cursor: pointer;
        color: black;
        transition: background-color 0.3s ease;
      }

      button:hover {
        background-color: #ffc30c;
        opacity: 0.8;
      }

    }

}

@media only screen and (max-width: 1099px) {
  .formContainer {
    .form-parent{
      flex-direction: column !important;
    }

    .form-ui{
      width: 100% !important;
    }

    .form{
      margin-bottom: 300px;
      width: 100% !important;
    }
  }
}

@media only screen and (max-width: 599px) {
  .formContainer {

    button{
      margin-left:0px !important;
    }
    .input-group{
      display: flex;
      flex-direction: column;
    }
  }
}

body{
  background-image: url('../../assets/lightBackground.jpg');
  background-size: cover;
}

