#home-container {
    min-height: 5vh;
    height: 90vh;
    background-color: white;
    display: flex;
    flex-direction: column;
  
    button:hover {
      background-color: #70a132; // Change to the desired background color on hover
      color: #fff; // Change to the desired text color on hover
    }

    .child {
      background-color: white;
      padding-top: 5px;
      margin-left: 75px;
      margin-right: 7px;
      margin-bottom: 7px;
      max-height: 100%;
      overflow-y:visible;
      min-height: 100%;
      .iframe{
        width: 100%;
        height: 90%;
      }
    }
    
  
    .header {
      background-color: white;
      width: "100%";  
      margin-left: 15px;
    }
    .tableheader {
      background-color: #70a132;
      width: "100%";
      margin-top: 5px;
      margin-bottom: 10px;
      padding: 7px;
      border-radius: 5px;
      margin-right: 1px;
      button:hover {
        background-color: #fff; // Change to the desired background color on hover
        color: #70a132; // Change to the desired text color on hover
      }
    }
    .table {
      height:100%;
    }
  }