@font-face {
    font-family: 'Margarosa';
    src: local('Margarosa'), url(../../../public/fonts/Margarosa.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

body{
    background-image: url('../../assets/lightBackground.jpg');
    background-size: cover;
  }

.bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 50px !important;
    padding-bottom: 300px !important;
}

.bottom__logo {
    width: 200px;

    margin-bottom: 10px;
    margin-left: 400px;
    height: 200px;
}

.bottom__text {
    flex-grow: 1;

}

.bottom__text a {
    color: inherit;
    text-decoration: none;
    font-family: 'Margarosa';
    margin-left: 30px;

}

.bottom__text span {
    font-family: 'Margarosa';
    margin-left: 30px;
}


.bottom__image {
    width: 300px;
    height: 300px;
    margin-right: 200px;
}

@media only screen and (min-width: 900px) and (max-width: 1281px) {
    .bottom__logo {
        width: 200px;
        margin-bottom: 7px;
        margin-left: 159px;
        height: 200px;
    }
}

@media only screen and (max-width: 899px) {
    .explore-bottom {
        display: flex;
        flex-direction: column !important;
        width: 100%;
        justify-content: center !important;
        align-items: center !important;

        .bottom__logo{
            margin-left: 0;
        }

        .bottom__image{
            margin-left: 0;
            margin-right: 0;
        }
    }
}