#home-container {
  min-height: 5vh;
  background-color: white;
  display: flex;
  flex-direction: column;

  .child {
    background-color: white;
    padding-top: 5px;
    margin-left: 75px;
    margin-right: 7px;
    margin-bottom: 7px;
    max-height: 100%;
    overflow-y: visible;
  }

  .header {
    background-color: white;
    width: "100%";
    margin-left: 15px;
  }

  .tableheader {
    background-color: #70a132;
    width: "100%";
    margin-top: 5px;
    margin-bottom: 10px;
    padding: 7px;
    border-radius: 5px;
    margin-right: 1px;
  }

  .table {
    height: 100%;
  }
}

.customheader {
  padding-left: 5px;
}
.addText {
  margin-top: 30px;
  // padding: 10px;
  border: none;
  border-bottom: 2px solid #70a132;
  // width: 40vw;
  margin-bottom: 10px;
}
.drop-down{
  // margin-top: 30px;
  // padding: 10px;
  border: none;
  border-bottom: 2px solid #70a132;
  // width: 40vw;
  margin-bottom: 10px;
  width: 100%;
  outline: none;
}

// modal.scss

.text-modal-dialog {
  .MuiDialog-paper {
    border-radius: 10px; // Adjust the value as needed
    width: 60vw;
  }

  .MuiDialogTitle-root {
    background-color: #70a132;
    color: white;
  }

  .MuiDialogContent-root {
    padding: 16px;
  }

  .addText {
    margin-top: 30px;
    // padding: 10px;
    border: none;
    border-bottom: 2px solid #70a132;
    // width: 40vw;
    margin-bottom: 10px;
  }

  .MuiDialogActions-root {
    justify-content: flex-end;
  }

  .MuiButton-root {
    margin-left: 8px;
    color: #70a132;
  }

  .checkBox {
    color: #70a132;
  }
}