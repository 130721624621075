body{
    background-image: url('../../assets/lightBackground.jpg');
    background-size: cover;
  }

@media only screen and (max-width: 1099px) {
    .downloadContainer{
        .content-box{
            flex-direction: column !important;
            width: 100% !important;

            .left{
                width: 100% !important;

                img{
                    width: 80% !important;
                }
            }

            .right{
                margin-top: 50px;
                width: 100% !important;
                margin-bottom: 300px;
            }
        }
    }
}

@media only screen and (max-width: 550px) {
    .downloadContainer{
        .top{
            .top-left{
                display: flex;
                flex-direction: column;
                padding-left: 10px;
                padding-right: 10px;
                justify-content: center;
                align-items: center;
            }
        }
        .content-box{
            .left{
                img{
                    width: 80% !important;
                }
            }
            .right{
                .buttons{
                    flex-direction: column !important;
                }
            }
        }
    }
}