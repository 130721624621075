.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.logo-container {
  margin-bottom: 0px;
  margin-top: 10vh;
}

.logo-style {
  width: 100vw;
  height: auto;
}

h2 {
  margin-bottom: 20px;
  margin-top: 0px;
  font-weight: 500;
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 300px;
  align-items: center;
}

.form-group {
  margin-bottom: 10px;
}

.input-field {
  padding: 10px;
  border: none;
  border-bottom: 2px solid #70a132;
  width: 70vw;
  margin-bottom: 10px;
  outline: none;
  transition: border-bottom-color 0.3s ease;
}

.login-button {
  padding: 10px;
  background-color: #70a132;
  color: #fff;
  border: none;
  cursor: pointer;
  width: 75vw;
  border-radius: 8px;
  height: 4vh;
}

.frg-pass {
  background-color: #fff !important;
  color: #70a132 !important;
  width: 8vw !important;
  font-weight: bold;
}

.forget-password {
  text-align: center;
  color: #70a132;
  text-decoration: none;
  font-weight: 500;
}

@media (min-width: 750px) {
  .logo-style {
    width: 50vw;
    height: auto;
  }
  .logo-container {
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .input-field {
    padding: 10px;
    border: none;
    border-bottom: 2px solid #70a132;
    width: 40vw;
    margin-bottom: 10px;
  }
  .login-button {
    padding: 10px;
    background-color: #70a132;
    color: #fff;
    border: none;
    cursor: pointer;
    width: 40vw;
    border-radius: 8px;
    height: 5vh;
  }
}