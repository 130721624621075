#home-container {
  min-height: 5vh;
  background-color: white;
  display: flex;
  flex-direction: column;

  .file-upload {
    text-align: center;
    border: 3px dashed #70a132;
    padding: 1.5rem;
    position: relative;
    cursor: pointer;
  }

  .file-upload p {
    font-size: 0.87rem;
    margin-top: 10px;
    color: #70a132;
  }

  .file-upload input {
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
  }

  .child {
    background-color: white;
    padding-top: 5px;
    margin-left: 75px;
    margin-right: 7px;
    margin-bottom: 7px;
    max-height: 100%;
    overflow-y: visible;
  }

  .header {
    background-color: white;
    width: "100%";
    margin-left: 15px;
  }

  .tableheader {
    background-color: #70a132;
    width: "100%";
    margin-top: 5px;
    margin-bottom: 10px;
    padding: 7px;
    border-radius: 5px;
    margin-right: 1px;
  }

  .table {
    height: 100%;
  }
}

.addText {
  margin-top: 30px;
  // padding: 10px;
  border: none;
  border-bottom: 2px solid #70a132;
  // width: 40vw;
  margin-bottom: 10px;
}

// modal.scss

.addText {
  margin-top: 30px;
  // padding: 10px;
  border: none;
  border-bottom: 2px solid #70a132;
  // width: 40vw;
  margin-bottom: 10px;
}

.text-modal-dialog {
  .MuiDialog-paper {
    border-radius: 10px; // Adjust the value as needed
    width: 60vw;
  }

  .MuiDialogTitle-root {
    background-color: #70a132;
    color: white;
  }

  .MuiDialogContent-root {
    padding: 16px;
  }

  .addText {
    margin-top: 30px;
    // padding: 10px;
    border: none;
    border-bottom: 2px solid #70a132;
    // width: 40vw;
    margin-bottom: 10px;
  }

  .MuiDialogActions-root {
    justify-content: flex-end;
  }

  .MuiButton-root {
    margin-left: 8px;
    color: #70a132;
  }

  .checkBox {
    color: #70a132;
  }


}

.ItemList {
  // display: flex;
  // flex-direction: row;
  // flex-wrap: wrap;
  overflow: auto;
  max-height: 34vh;
  border: 2px solid #70a132;
  ;
  border-radius: 4px;
  padding: 5px;
  margin: 6px;
  width: auto;
}