body{
  background-image: url('../../assets/lightBackground.jpg');
  background-size: cover;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #70A132;
  padding: 20px;

  position: fixed;
  bottom: 0;
  width: 100%;

  .footer__left,
  .footer__middle,
  .footer__right {
    margin-bottom: 10px;
    /* Add margin between sections */
  }

  .footer__icon {
    color: black;
    font-size: 26px;
    margin-right: 10px;
    cursor: pointer;
  }

  .footer__middle {
    font-size: 1.3rem;
    font-weight: 600;
  }

  .footer__right {
    font-size: 1.2rem;
    font-weight: 600;
    display: flex;
    align-items: center;

    .footer__lock-icon {
      color: #70a132;
      margin-left: 5px;
      cursor: pointer;
    }

    
  }

  .disclaimer-link {
    color: black; /* Link color */
    text-decoration: underline; /* Underline the link */
    cursor: pointer; /* Pointer cursor on hover */
}

.disclaimer-link:hover {
    color: black; /* Color change on hover */
}

  @media screen and (max-width: 662px) {
    flex-direction: column;
    align-items: flex-start;

    .footer__left,
    .footer__middle,
    .footer__right {
      margin-bottom: 20px;
      margin-left: 80px;
      font-size: 1rem;
    }

    .footer__left {
      margin-left: 80px;

      .footer__icon {
        font-size: 20px;
      }
    }
  }

  // @media screen and (min-width: 607px) {
  //   flex-direction: column; 
  //   align-items: flex-start; 

  //   .footer__left,
  //   .footer__middle,
  //   .footer__right {
  //     margin-bottom: 20px;
  //     margin-left: 80px;
  //     font-size: 1rem;
  //   }

  //   .footer__left{
  //     margin-left: 80px;

  //     .footer__icon{
  //       font-size: 20px;
  //     }
  //   }
  // }

  // @media screen and (max-width: 781px) {
  //   flex-direction: column; 
  //   align-items: flex-start; 

  //   .footer__left,
  //   .footer__middle,
  //   .footer__right {
  //     margin-bottom: 20px;
  //     margin-left: 80px;
  //     font-size: 1rem;
  //   }

  //   .footer__left{
  //     margin-left: 80px;

  //     .footer__icon{
  //       font-size: 20px;
  //     }
  //   }
  // }
}