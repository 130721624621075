@media only screen and (max-width: 1099px) {
    .homeContainer{
        .top{
            flex-direction: column !important;
            width: 100%;
            padding-top: 20px;
            
            .top-left{
                width: 100% !important;
                align-items: center !important;
                justify-content: center !important;

                .welcome-text {
                    width: 90% !important;
                    justify-content: center;
                    align-items: center !important;
                }
            }

            .top-right{
                width: 100% !important;

                img {
                    width: 100% !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 1099px) {
    .homeContainer{
        padding-bottom: 200px;
        .middle{
            flex-direction: column !important;
            width: 100% !important;
            justify-content: center;
            align-items: center;
        }
    }
}

body{
    background-image: url('../../assets/lightBackground.jpg');
    background-size: cover;
  }
