@font-face {
    font-family: 'Margarosa';
    src: local('Margarosa'), url(../../../public/fonts/Margarosa.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

.navbar{
    position: sticky;
    top: 0px;
    height: 70px;
    background-color:#70A132;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 30px 0px 30px;
    // margin-bottom: -70px;
    
    
}


.navbar__list{
    display: flex;
    list-style: none;
    // margin-left: 500px;
    margin-left: 10px;
   
}

.navbar__item{
    white-space: nowrap;
   margin-left: 20px;
}

.navbar__link{
    font-size: 1rem;
    font-weight: 600;
    text-decoration: none;
    color: black;
    text-align: center;
    padding: 10px;
    margin: 0px 5px;
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    border-color: transparent;
    transition: 250ms;
  
}

.navbar__link:hover{
    border-color: white;
    transition: 250ms;
}

.navbar__menu{
    cursor: pointer;
    color: white;
    display: none;
}

body{
    background-image: url('../../assets/lightBackground.jpg');
    background-size: cover;
  }


    

@media only screen and (min-width: 245px) and (max-width: 910px) {
    .navbar{
        justify-content: flex-start;
    }

    .navbar__list{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        position: fixed;
        top: 70px;
        left: -120%;
        width: 110%;
        height: 300px;
        transition: all 0.5s;
        margin-left: 0;
    }
    
    .navbar__list--active{
    left: -10%;
    }
    
    .navbar__item{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0;
        flex: 1;
    }
    
    .navbar__link{
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        margin: 0;
       
        background-color: #70A132;
    }
    
    .navbar__link:hover{
        opacity: 0.9;
    }
    
    .navbar__menu{
    
        display: block;
    }
    
    }

    