@font-face {
    font-family: 'Margarosa';
    src: local('Margarosa'), url(../../../public/fonts/Margarosa.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  .body__carousel {
    margin: 0;
    padding: 0;
    
    height: 130vh;
    background-image: url('../../assets/lightBackground.jpg');
    background-size: cover;
  }
  

  /* Main Content Styles */
  .main-content {
    display: flex;
    flex-direction: column;
    max-width: 80%;
    margin: 0 auto;
    /* padding: 20px; */
    // color: #EEEEEE;
    justify-content: center;
  }
  
  
  .partner__title {
    //margin-bottom: 40px;
    // color: #EEEEEE;
    padding: 3rem 0 0 0 ;
  
  }
  
  .partner__title h2 {
    font-size: 24px;
    margin-bottom: 20px;
    // color: #EEEEEE;
  }
  
  .partner__members {
    display: flex;
    // justify-content: space-between;
    // color: #EEEEEE;
    height: 80%;
  }
  
  
  .partner__member__image {
    display: flex;
    width: 150px;
    height: 170px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 5px;
  }
  
  .centered-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }


.carousel-item {
    transition: transform 0.5s step-start;
    margin: 0px;
    padding-top: 40px;
    padding-bottom: 10px;
    display: grid;
    text-align: center;
    justify-content: center;
    /* justify-items: center;  */
}

.carousel-item:hover {
    transform: scale(1.1);
    height: fit-content;
}

.partners{
    justify-content: center;
    text-align: center;
    font-family: 'Margarosa';
    font-size: 2.5rem;
    color: #58595b;
     margin-top: 15.5rem;
     
}

.thank{
    display: flex;
    justify-content: center;
    align-items: center;
  
}

.thank__body{
    margin: 0 0 0 0;
    text-align: center;
    line-height: 2.5rem;
    font-size: 22px;
}


 .slick-prev, .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  color: black;
  border: 1px solid black;
  border-radius: 220%;
  outline: none;
  background: black;
}

body{
  background-image: url('../../assets/lightBackground.jpg');
  background-size: cover;
}



.custom-arrow {
  &::before {
    color: white; 
    font-size: 20px;
  }
  
  &.slick-prev {
    left: 10px;
  }
  
  &.slick-next {
    right: 10px;
  }
}

.custom-arrow {
  display: flex !important;
  justify-content: center;
  align-items: center;
  background: #70a132;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 1;
  transition: background 0.3s ease-in-out;

  &::before {
    color: white;
    font-size: 24px;
  }

  &.slick-prev {
    left: 10px;
  }

  &.slick-next {
    right: 10px;
  }

  &:hover {
    background: #557e25;
  }
}

