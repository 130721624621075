.servicesContainer {
  margin-bottom: 500px !important;
  .appointmentBtn {
    background-color: #70a132;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    opacity: 0.8;
    // padding: 20px;
    display: 'flex';
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    color: black;
    transition: background-color 0.3s ease;
    /* Add transition effect for background-color */
  }

  .appointmentBtn:hover {
    background-color: #8bc34a;
    /* Change color on hover */
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1200px) {
  .servicesContainer {
    margin-bottom: 700px !important;
  }
}

@media only screen and (max-width: 1099px) {
  .servicesContainer{
      .top{
          flex-direction: column !important;
          width: 100%;
          padding-top: 20px;

          img {
            display: none;
          }
          div{
            width: 100% !important;
          }

          .appointmentBtn{
            margin-bottom: 300px !important;
          }
      }
  }
}

body{
  background-image: url('../../assets/lightBackground.jpg');
  background-size: cover;
}

