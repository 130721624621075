.logo {
    width: 10vw;
    height: auto;
}

.drawer-expanded {
    width: 240px;
    transition: width 0.3s ease-in-out;
}

.drawer-collapsed {
    width: 64px;
    transition: width 0.3s ease-in-out;
}

.nav-collapsed{
    width: 10vw;
    height: 100vh;
    background-color: '#70a132';
}

.sidenav{
    ::-webkit-scrollbar{
        width: 8px;
    }
    ::-webkit-scrollbar-thumb{
        background: #fff;
        border-radius: 6px;
    }

    .menu-burger{
        transform: translateX(-16px);
    }
}