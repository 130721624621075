body{
    background-image: url('../../assets/lightBackground.jpg');
    background-size: cover;
  }

  .slider {
    display: flex;
    align-items: center;
    // position: relative;
    bottom: 150px;
    opacity: 0;
    transform: scale(85%);
    transition: 0s;

  }

.slider--zoom{
    opacity: 1;
    transform: scale(100%);
    transition: 0s;
}

.slider__image{
    width: 10%;
    margin-left: 80px;
}

.slider__content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    padding: 20px;
}

.margarosa {
    font-family: 'Margarosa';
}

.slider__txt{
    line-height: 3.5rem;
    font-size: 20px;
   
}

.exploreContainer{
  margin-bottom: 300px !important;
}

@media only screen and (max-width: 1099px) {
  .slider__image{
    width: 15%;
    margin-left: 80px;
}



  .exploreContainer{

    .explore-image{
      max-width: 100% !important;
    }
    
      .slider-box{
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
      }

      .top{
          flex-direction: column !important;
          width: 100%;
          padding-top: 20px;
          
          .top-left{
              width: 100% !important;
              align-items: center !important;
              justify-content: center !important;

              .welcome-text {
                  width: 90% !important;
                  justify-content: center;
                  align-items: center !important;
              }
          }

          .top-right{
              width: 100% !important;

              img {
                  width: 100% !important;
              }
          }
      }
  }
}