.app {
  display: flex;
  margin: 0px;
}

.content {
  flex: 1;
  /* padding: 20px; */
}

h1 {
  margin-top: 0;
}

p {
  margin-bottom: 20px;
}
